<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Institutions</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      size="small"
      variant="outlined"
      color="primary"
      class="flex items-center gap-2 border-primary mt-2 md:mt-0 rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Education</span>
    </easiButton>
  </div>

  <hr class="text-dark-100" />

  <div
    style="max-height: 500px"
    class="p-6 md:p-8 flex flex-col gap-6 overflow-y-auto"
    v-if="education && education.length"
  >
    <div
      v-for="(item, i) in education"
      :key="i"
      class="grid gap-2 grid-cols-12 border-b last:border-b-0 border-dark-100 pb-6 mt-6"
    >
      <div class="w-full flex justify-end col-span-12">
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateId(item)"
          variant="outlined"
          color="primary"
          class="w-20 border border-primary"
        >
          <span class="flex items-center text-sm gap-1">
            <img
              class="w-4 -ml-2"
              src="@/assets/icons/edit-primary.svg"
              alt=""
            />

            <span> Edit</span>
          </span>
        </easiButton>
      </div>
      <div
        class="flex flex-col col-span-12 sm:col-span-8 md:col-span-12 xl:col-span-8 -mt-8"
      >
        <div class="flex flex-col gap-4">
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Institution Name</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.institutionName
            }}</span>
          </div>

          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Degree</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.degree
            }}</span>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Course of Study</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.courseOfStudy
            }}</span>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Grade</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.grade
            }}</span>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Graduation Year</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.graduationYear
            }}</span>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Description</span>
            <span class="text-base text-dark-800 font-medium">{{
              item.description
            }}</span>
          </div>
        </div>
      </div>
      <div
        @click="onPreview(convertToHttpsUrl(item.imageLink))"
        class="bg-background-50 relative p-4 h-full col-span-12 sm:col-span-4 md:col-span-12 xl:col-span-4 flex items-center justify-center cursor-pointer"
      >
        <div
          class="w-full h-full top-0 absolute opacity-20 hover:bg-black"
        ></div>
        <img
          v-if="item.imageLink && checkFileType(item.imageLink) === 'image'"
          :src="item.imageLink"
          class="object-contain h-60 cursor-pointer"
          alt=""
        />

        <iframe
          v-else-if="item.imageLink && checkFileType(item.imageLink) === 'doc'"
          :src="convertToHttpsUrl(item.imageLink)"
          class="object-contain w-full h-full border-0 h-60 cursor-pointer"
          style="border: none"
        ></iframe>

        <span v-else> No Image Found </span>
      </div>
    </div>
  </div>

  <div v-else class="my-8">
    <easiEmptyState text="No Institutions added" />
  </div>
  <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
    <EducationHistoryAdd @complete="showAdd = false" type="add" />
  </easiModal>
  <easiImageView :visible="visibleRef" :imgs="imgsRef" @hide="onHide">
  </easiImageView>

  <easiModal
    :lite="true"
    :isBorder="false"
    :compliance="true"
    v-if="preview"
    @close="preview = false"
  >
    <div
      style="height: calc(100vh - 250px)"
      class="w-full flex flex-col items-center h-auto overflow-y-scoll"
    >
      <iframe
        ref="pdfPreview"
        :src="imgsRef"
        style="width: 98%; height: 100%"
      ></iframe>
    </div>
  </easiModal>
</template>

<script setup>
import EducationHistoryAdd from "@/components/Employee/Edit/Education/EducationHistory.vue";
import { computed, reactive, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["edu"]);

const store = useDataStore();
const route = useRoute();

const preview = ref(false);
const showAdd = ref(false);
const visibleRef = ref(false);
const imgsRef = ref([]);

const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const education = computed(
  () => employee && employee.value.education && employee.value.education.history
);
function updateId(obj) {
  emit("edu", obj);
}

function convertToHttpsUrl(url) {
  if (!url.startsWith("https://")) {
    url = url.replace("http://", "https://");
  }
  return url;
}

function checkFileType(url) {
  var extension = url.split(".").pop().toLowerCase();

  if (
    extension === "pdf" ||
    extension === "docx" ||
    extension === "txt" ||
    extension === "doc"
  ) {
    return "doc";
  } else if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "gif"
  ) {
    return "image";
  } else {
    return "The link is not a PDF document or image.";
  }
}
const onPreview = (img) => {
  console.log(img, "img");
  imgsRef.value = img;

  if (checkFileType(img) === "doc") {
    preview.value = true;
  } else {
    visibleRef.value = true;
  }
};

const onHide = () => (visibleRef.value = false);
</script>

<style scoped></style>
