<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Award List</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      size="small"
      variant="outlined"
      color="primary"
      class="flex items-center gap-2 mt-2 md:mt-0 border-primary rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Award</span>
    </easiButton>
  </div>

  <hr class="text-dark-100" />
  <div
    v-if="awards && awards.length"
    style="max-height: 500px"
    class="p-6 md:p-8 flex flex-col gap-6 overflow-y-auto"
  >
    <div
      v-for="(item, i) in awards"
      :key="i"
      class="grid gap-2 grid-cols-12 border-b last:border-b-0 border-dark-100 pb-6"
    >
      <div class="w-full flex justify-end col-span-12">
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateId(item)"
          size="small"
          variant="outlined"
          class=""
        >
          <span class="flex items-center text-base gap-3">
            Edit
            <img class="w-4" src="@/assets/icons/edit2.svg" alt="" />
          </span>
        </easiButton>
      </div>
      <div
        class="relative flex flex-col col-span-12 sm:col-span-8 md:col-span-12 xl:col-span-8"
      >
        <div class="flex flex-col gap-4 -mt-4">
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Awarding Organisation</span>
            <span class="text-base text-dark-800 font-medium">
              {{ display(item.awardingOrganisation) }}
            </span>
          </div>

          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Award Received</span>
            <span class="text-base text-dark-800 font-medium"
              >{{ display(item.awardReceived) }}
            </span>
          </div>
          <div class="flex flex-col">
            <span class="text-sm text-dark-500">Date Obtained</span>
            <span class="text-base text-dark-800 font-medium"
              >{{ display(item.dateObtained) }}
            </span>
          </div>
        </div>
      </div>

      <div
        @click="onPreview(convertToHttpsUrl(item.imageLink))"
        class="bg-background-50 p-4 relative col-span-12 sm:col-span-4 md:col-span-12 xl:col-span-4 flex flex-col items-center justify-center w-full h-full"
      >
        <div
          class="w-full h-full top-0 absolute opacity-20 hover:bg-black"
        ></div>
        <img
          v-if="item.imageLink && checkFileType(item.imageLink) === 'image'"
          :src="convertToHttpsUrl(item.imageLink)"
          class="object-contain h-60 cursor-pointer"
          alt=""
        />

        <iframe
          v-else-if="item.imageLink && checkFileType(item.imageLink) === 'doc'"
          :src="convertToHttpsUrl(item.imageLink)"
          class="object-contain w-full h-full border-0 h-60 cursor-pointer"
          style="border: none"
        ></iframe>
        <span v-else>No Image</span>
      </div>
    </div>
  </div>
  <div v-else class="my-8">
    <easiEmptyState text="No Awards added" />
  </div>
  <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
    <AwardAdd type="add" @complete="showAdd = false" />
  </easiModal>

  <easiImageView
    :visible="visibleRef"
    :imgs="imgsRef"
    @hide="onHide"
  ></easiImageView>
  <easiModal
    :lite="true"
    :isBorder="false"
    :compliance="true"
    v-if="preview"
    @close="preview = false"
  >
    <div
      style="height: calc(100vh - 250px)"
      class="w-full flex flex-col items-center h-auto overflow-y-scoll"
    >
      <iframe
        ref="pdfPreview"
        :src="imgsRef"
        style="width: 98%; height: 100%"
      ></iframe>
    </div>
  </easiModal>
</template>

<script setup>
import AwardAdd from "@/components/Employee/Edit/Education/Award.vue";
import { computed, reactive, ref } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const store = useDataStore();
const route = useRoute();

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["award"]);

const showAdd = ref(false);
const visibleRef = ref(false);
const imgsRef = ref([]);
const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const awards = computed(
  () => employee && employee.value.education && employee.value.education.awards
);
const preview = ref(false);

const onHide = () => (visibleRef.value = false);
function updateId(obj) {
  emit("award", obj);
}
const display = (arg) => {
  return arg ? arg : "N/A";
};

function convertToHttpsUrl(url) {
  if (!url.startsWith("https://")) {
    url = url.replace("http://", "https://");
  }
  return url;
}

function checkFileType(url) {
  var extension = url.split(".").pop().toLowerCase();

  if (
    extension === "pdf" ||
    extension === "docx" ||
    extension === "txt" ||
    extension === "doc"
  ) {
    return "doc";
  } else if (
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === "png" ||
    extension === "gif"
  ) {
    return "image";
  } else {
    return "The link is not a PDF document or image.";
  }
}

const onPreview = (img) => {
  imgsRef.value = img;

  if (checkFileType(img) === "doc") {
    preview.value = true;
  } else {
    visibleRef.value = true;
  }
};
</script>

<style></style>
